h1,
h2,
h3 {
  font-family: "Lato", sans-serif;
  line-height: 1;
}

@media (max-width: 48rem) {
  .hide-on-mobile {
    display: none;
  }
}

@media (min-width: 48.0625rem) {
  .hide-on-desktop {
    display: none;
  }
}

.container {
  max-width: 69.375rem;
  margin: 0 auto;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 100%;
}

body {
  overflow-x: hidden;
  position: relative;
}

a {
  display: inline-block;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  transition: all 250ms ease-in-out;
}

.section-header__icon {
  width: 3.4375rem;
  height: 3.4375rem;
  margin: 0 auto 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-header__icon img {
  height: 100%;
  width: auto;
}

.section-header__title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 2.125rem;
  text-align: center;
  margin-bottom: 3.125rem;
  color: #1d3557;
}

@media (min-width: 69.4375rem) {
  .section-header__title {
    font-size: 2.25rem;
  }
}

@keyframes slideIn {
  0% {
    right: -200%;
    visibility: hidden;
  }
  100% {
    right: 0;
    visibility: visible;
  }
}

.header {
  background: url("mobile-header-bg.f3f63832.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  padding: 2.25rem 2.25rem 5.625rem;
  margin-bottom: 3.75rem;
  position: relative;
}

@media (min-width: 48.0625rem) {
  .header {
    background: url("tablet-header-bg.21d3ef06.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
  }
}

@media (min-width: 69.4375rem) {
  .header {
    background: url("desktop-header-bg.c86d9b4a.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
  }
}

.header .mobile-menu {
  width: 100vw;
  height: 100vh;
  padding: 1.5rem 1.5rem 1.5rem 2.5rem;
  position: fixed;
  top: 0;
  right: -200%;
  visibility: hidden;
  background-color: white;
  z-index: 100;
  transition: all 500ms ease-in-out;
}

.header .mobile-menu__link {
  display: block;
  text-align: center;
  color: #e63946;
}

.header .mobile-menu__link:not(:last-child) {
  margin-bottom: 1.75rem;
}

.header .mobile-menu .logo {
  margin-bottom: 2.625rem;
}

.header .mobile-menu.active {
  animation: slideIn 350ms ease-in-out forwards;
}

.header .close-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: 1.875rem;
}

.header .close-btn span {
  display: block;
  width: 2.1875rem;
  height: 0.3125rem;
  background-color: #e63946;
  transform-origin: -0.1875rem 0.1875rem;
}

.header .close-btn span:first-child {
  margin-bottom: 1.5625rem;
  transform: rotate(45deg);
}

.header .close-btn span:last-child {
  transform: rotate(-45deg);
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.125rem;
}

.nav__links {
  list-style: none;
}

.nav__item {
  list-style: none;
  display: inline-block;
}

.nav__item:not(:last-child) {
  margin-right: 2.5rem;
}

.nav__link {
  color: white;
  display: inline-block;
}

.nav__menu-btn {
  width: 2.8125rem;
  height: auto;
  background-color: white;
  border: none;
  border-radius: 0.3125rem;
  position: fixed;
  right: 2.25rem;
  padding: 0.3125rem;
  z-index: 99;
  cursor: pointer;
}

.nav__menu-btn span {
  display: block;
  width: 100%;
  height: 0.3125rem;
  background-color: #e63946;
}

.nav__menu-btn span:not(:last-child) {
  margin-bottom: 0.3125rem;
}

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero__text {
  width: 18.5rem;
}

@media (min-width: 48.0625rem) {
  .hero__text {
    width: 22.1875rem;
  }
}

.hero__title {
  font-size: 3rem;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 3.625rem;
  color: white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 1.25rem;
}

@media (min-width: 69.4375rem) {
  .hero__title {
    font-size: 4rem;
    line-height: 4.8125rem;
  }
}

.hero__subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media (min-width: 69.4375rem) {
  .hero__subtitle {
    font-size: 1.5rem;
    line-height: 1.8125rem;
  }
}

.hero__img {
  width: 15.4375rem;
}

@media (max-width: 38.5rem) {
  .hero__img {
    display: none;
  }
}

@media (min-width: 69.4375rem) {
  .hero__img {
    width: 17.125rem;
  }
}

.hero__img img {
  width: 100%;
  height: auto;
}

.hero__product-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 1.875rem;
  text-align: center;
  color: white;
  margin-bottom: 1.5rem;
}

@media (min-width: 69.4375rem) {
  .hero__product-title {
    font-size: 2rem;
    line-height: 2.4375rem;
  }
}

.hero__product-price {
  background-color: #1d3557;
  width: 14.375rem;
  height: 5.625rem;
  border-radius: 6.25rem;
  padding: 0.3125rem;
  display: flex;
  align-items: center;
}

@media (min-width: 69.4375rem) {
  .hero__product-price {
    width: 21.0625rem;
    height: 7.6875rem;
  }
}

.hero__product-price .currency {
  display: block;
  width: 5rem;
  height: 5rem;
  background: #a8dadc;
  border-radius: 6.25rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 3.125rem;
  color: #1d3557;
}

@media (min-width: 69.4375rem) {
  .hero__product-price .currency {
    width: 7.0625rem;
    height: 7.0625rem;
    font-size: 4.5rem;
    margin-right: 2.1875rem;
  }
}

.hero .price-wrapper {
  text-align: center;
  color: #a8dadc;
}

.hero .price-wrapper .price-title {
  display: block;
  margin-bottom: 0.375rem;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.375rem;
  align-items: center;
}

@media (min-width: 69.4375rem) {
  .hero .price-wrapper .price-title {
    font-size: 1.5rem;
    line-height: 1.8125rem;
    margin-bottom: 0.625rem;
  }
}

.hero .price-wrapper .price-amount {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 3.125rem;
  line-height: 2.875rem;
}

@media (min-width: 69.4375rem) {
  .hero .price-wrapper .price-amount {
    font-size: 4.5rem;
    line-height: 3.5rem;
  }
}

.flavors {
  padding: 0 2.25rem;
  margin-bottom: 3.75rem;
}

.flavors__selection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5rem 5rem;
  flex-wrap: wrap;
}

.flavors__option {
  width: 16.25rem;
  height: 6.25rem;
  background-color: #e63946;
  border-radius: 3.125rem;
  padding: 0 0.625rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.flavors__option-img {
  width: 6.25rem;
  height: 6.25rem;
}

.flavors__option-img img {
  width: 100%;
}

.flavors__option-name {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  color: white;
}

.sellers {
  padding: 0 2.25rem;
  margin-bottom: 3.75rem;
}

.sellers-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem 2.75rem;
  flex-wrap: wrap;
}

@media (min-width: 48.0625rem) {
  .sellers-list {
    gap: 2.5rem 3.75rem;
  }
}

@media (min-width: 69.4375rem) {
  .sellers-list {
    gap: 3.75rem 5.625rem;
  }
}

.sellers__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 48.0625rem) {
  .sellers__option {
    gap: 0 1rem;
  }
}

@media (min-width: 69.4375rem) {
  .sellers__option {
    height: 18.75rem;
  }
}

.sellers__option-img {
  width: 6.875rem;
  height: 12.5rem;
  display: flex;
  align-items: center;
}

@media (min-width: 69.4375rem) {
  .sellers__option-img {
    width: 10.375rem;
  }
}

.sellers__option-img img {
  width: 100%;
}

.sellers__option-info {
  width: 10rem;
}

@media (min-width: 69.4375rem) {
  .sellers__option-info {
    width: 17.8125rem;
  }
}

.sellers__option-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: center;
  color: #457b9d;
  margin-bottom: 0.625rem;
}

@media (min-width: 69.4375rem) {
  .sellers__option-title {
    font-size: 1.5rem;
    line-height: 1.8125rem;
    margin-bottom: 1rem;
    text-align: left;
  }
}

.sellers__option-price {
  width: 10rem;
  height: 3.75rem;
  background: #e63946;
  border-radius: 6.25rem;
  padding: 0.1875rem;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  color: white;
  display: flex;
  align-items: center;
}

.sellers__option-price .currency {
  width: 3.375rem;
  height: 3.375rem;
  border-radius: 6.25rem;
  margin-right: 0.8125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  color: #e63946;
}

.reviews {
  padding: 0 2.25rem;
  margin-bottom: 3.75rem;
}

.reviews__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5rem 3.875rem;
  flex-wrap: wrap;
}

@media (min-width: 69.4375rem) {
  .reviews__wrapper {
    gap: 2.5rem 3.1875rem;
  }
}

.reviews__sample {
  width: 17.4375rem;
}

.reviews__sample blockquote {
  font-family: "Roboto", sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1875rem;
  text-align: center;
  color: #524c4c;
  margin-bottom: 1rem;
}

.reviews__sample cite {
  display: block;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  color: #457b9d;
  text-align: center;
}

.contact {
  padding: 0 2.25rem;
  margin-bottom: 3.75rem;
}

.contact__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2.5rem 6.25rem;
}

@media (min-width: 69.4375rem) {
  .contact__wrapper {
    gap: 0rem 12.5rem;
  }
}

.contact__text {
  width: 13.75rem;
}

.contact__address, .contact__phone, .contact__email {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  text-align: center;
  color: #524c4c;
  margin-bottom: 1.25rem;
}

.contact__email {
  margin-bottom: 0;
}

.contact__map {
  width: 18.75rem;
  height: 11.875rem;
}

@media (min-width: 69.4375rem) {
  .contact__map {
    width: 24.625rem;
  }
}

.footer {
  padding: 3.75rem 2.375rem;
  background-color: #1d3557;
}

.footer__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 3.125rem 12.5rem;
}

@media (min-width: 48.0625rem) {
  .footer__wrapper {
    justify-content: flex-start;
    gap: 3.125rem 10rem;
  }
}

@media (min-width: 69.4375rem) {
  .footer__wrapper {
    justify-content: center;
    gap: 3.125rem 9rem;
  }
}

.footer__about {
  width: 18.75rem;
}

@media (min-width: 69.4375rem) {
  .footer__about {
    width: 21.25rem;
  }
}

.footer__title {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  text-align: center;
  color: #f2faef;
  margin-bottom: 1.25rem;
}

@media (min-width: 48.0625rem) {
  .footer__title {
    text-align: left;
  }
}

@media (min-width: 69.4375rem) {
  .footer__title {
    text-align: center;
  }
}

.footer__text {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 1.1875rem;
  text-align: center;
  color: #f2faef;
}

@media (min-width: 48.0625rem) {
  .footer__text {
    text-align: left;
  }
}

@media (min-width: 69.4375rem) {
  .footer__text {
    text-align: center;
  }
}

.footer__media {
  width: 8.25rem;
}

.footer__media-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1.4375rem;
}

.footer__media-link {
  display: block;
  width: 2.125rem;
  height: 2.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__media-link img {
  width: 100%;
  height: auto;
}

.footer__contact {
  width: 11.25rem;
}
/*# sourceMappingURL=index.07cb0ed8.css.map */
