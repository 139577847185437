$mont-f: "Montserrat", sans-serif;
$lato-f: "Lato", sans-serif;
$roboto-f: "Roboto", sans-serif;
$fw-r: 400;
$fw-b: 700;
$fw-xb: 900;

h1,
h2,
h3 {
  font-family: $lato-f;
  line-height: 1;
}
